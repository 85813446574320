import React from "react"
import styled from "styled-components"
import theme from "@assets/styles/theme"

import AppLayout from "@components/layout/App"
import CareerBandBox from "@components/elements/CareerBandBox"
import CAREER_LEVELS_CONTENT from "@content/career-levels.js"

import HeaderBg from "@assets/career-levels/banner_career_band.jpg"
import HeaderBgMobile from "@assets/career-levels/banner_career_band_mobile.jpg"

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 80px 8px;

  @media (max-width: 1023px) {
    padding: 24px 8px;
  }
`

const PAGE_HEADER_HEIGHT = "212px"
const PAGE_GREY_HEIGHT = "132px"

const PageHeader = styled.div`
  background-image: url(${HeaderBg});
  background-size: cover;
  background-position: center;
  height: ${PAGE_HEADER_HEIGHT};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;

  @media (max-width: 1023px) {
    background-image: url(${HeaderBgMobile});
    height: auto;
  }
`

const PageHeaderBoxes = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;

  @media (max-width: 1023px) {
    display: none;
  }
`

const PageHeaderGrey = styled.div`
  background-color: rgba(63, 63, 63, 0.9);
  color: ${theme.colors.grey.white};
  flex: 1;
  height: ${PAGE_GREY_HEIGHT};

  overflow: hidden;

  @media (max-width: 1023px) {
    background-color: transparent;
    padding: 0;
  }
`

const PageHeaderYellow = styled.div`
  background-color: ${theme.colors.secondary.yellow};
  width: 40%;
  height: 60px;

  @media (max-width: 1023px) {
    display: none;
  }
`

const PageHeaderTextContainer = styled.div`
  color: ${theme.colors.grey.white};
  width: min(1140px, calc(100vw - 32px));
  padding: 24px 0;
  z-index: 2;
  height: ${PAGE_GREY_HEIGHT};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1023px) {
    width: 100%;
    text-align: center;
    align-items: center;
    padding: 0;
    height: auto;
  }
`

const PageHeaderH2 = styled.h2`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 0 8px 0;
  max-width: 50%;

  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 21px;
    margin: 30px 0;
    max-width: none;
  }
`

const PageHeaderBody = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;

  @media (max-width: 1023px) {
    padding: 12px 18px;
    font-size: 12px;
    line-height: 20px;
    background-color: ${theme.colors.grey.greyBackground};
    color: ${theme.colors.grey.textDark};
    width: 100%;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px 0;

  @media (max-width: 1023px) {
    flex-direction: column;
    padding-bottom: 24px;
  }
`

const CareerBandContainer = styled.div`
  display: flex;
`

const CareerBandTitle = styled.div`
  margin-right: 16px;
  text-align: right;
  width: 200px;

  font-size: 24px;
  font-style: normal;
  font-weight: 700;

  color: ${props => props.theme.colors.primary.blue};

  @media (max-width: 1023px) {
    text-align: center;
    padding-bottom: 12px;
    margin-right: 0;
    font-size: 14px;
  }
`

const CAREER_BANDS = CAREER_LEVELS_CONTENT

export default function CareerBandsPage() {
  const getBlankCareerBandBox = (number = 0, prefix = "") => {
    const arr = []

    for (let i = 0; i < number; i++) {
      arr.push(<CareerBandBox blank key={`cbc-blank-${prefix}-${i}`} />)
    }

    return arr
  }

  return (
    <AppLayout>
      <PageHeader>
        <PageHeaderBoxes>
          <PageHeaderGrey />
          <PageHeaderYellow />
        </PageHeaderBoxes>
        <PageHeaderTextContainer>
          <PageHeaderH2>Career Bands & Levels</PageHeaderH2>
          <PageHeaderBody>
            Click on any level within the career bands to view criteria
          </PageHeaderBody>
        </PageHeaderTextContainer>
      </PageHeader>
      <PageContainer>
        <div style={{ paddingBottom: "16px" }}>
          {CAREER_BANDS.map((cb, cbIndex) => {
            return (
              <Row key={`career-level-${cbIndex}`}>
                <CareerBandTitle>{cb.name}</CareerBandTitle>

                <CareerBandContainer>
                  {getBlankCareerBandBox(cb.prePad, "pre")}
                  {cb.bands.map((b, bandIndex) => {
                    return (
                      <CareerBandBox
                        key={`career-level-${cbIndex}-${bandIndex}`}
                        careerBandName={cb.name}
                        careerBandSlug={cb.slug}
                        text={b.code}
                        bgColor={cb.bgColor}
                        textColor={cb.textColor}
                      />
                    )
                  })}
                  {getBlankCareerBandBox(cb.postPad, "post")}
                </CareerBandContainer>
              </Row>
            )
          })}
        </div>
      </PageContainer>
    </AppLayout>
  )
}
