import React from "react"
import styled from "styled-components"
import kebabCase from "lodash/kebabCase"

import UnstyledLink from "@components/elements/UnstyledLink"

import { getBandDetailsUrl, getCareerBandsComparisonUrl } from "@helpers/url"

const CbBox = styled.div`
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;

  font-size: 24px;
  font-style: normal;
  font-weight: 700;

  color: ${props => props.textColor || props.theme.colors.grey.white};
  background: ${props => props.bgColor || "#D6D7D740"};
  margin: 0 4px;

  @media (max-width: 1023px) {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;

    display: ${props => (props.hide ? "none" : "block")};
  }
`

const DesktopContent = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`

const MobileContent = styled.div`
  display: none;

  @media (max-width: 1023px) {
    display: block;
  }
`

const CareerBandBox = ({
  careerBandName,
  careerBandSlug,
  text,
  blank = false,
  bgColor,
  textColor,
}) => {
  if (blank) {
    return <CbBox hide></CbBox>
  }

  return (
    <>
      <DesktopContent>
        <UnstyledLink
          to={getCareerBandsComparisonUrl(kebabCase(careerBandName), text)}
        >
          <CbBox bgColor={bgColor} textColor={textColor}>
            {text}
          </CbBox>
        </UnstyledLink>
      </DesktopContent>

      <MobileContent>
        <UnstyledLink to={getBandDetailsUrl(careerBandSlug, text)}>
          <CbBox bgColor={bgColor} textColor={textColor}>
            {text}
          </CbBox>
        </UnstyledLink>
      </MobileContent>
    </>
  )
}

export default CareerBandBox
