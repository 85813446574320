import theme from "../src/assets/styles/theme"

const careerLevels = [
  {
    name: "Leadership",
    slug: "leadership",
    bgColor: theme.colors.secondary.green,
    textColor: theme.colors.grey.white,
    descriptions: [
      "Achieves goals through the work of others",
      "Management responsibilities include performance appraisals, pay reviews, training and development ​",
      "Job focus is on managing others and applying operational or strategic management skills",
    ],
    prePad: 5,
    postPad: 0,
    bands: [
      {
        code: "L1",
        headers: [
          {
            header: "General Profile",
            content: [
              "Supervises the daily activities of business support, technicians and skilled operations teams",
              "Sets priorities for the team to ensure task completion; coordinates work activities with other supervisors",
              "Decisions are guided by policies, procedures and the local business plan; receives guidance and oversight from manager",
              "Typically, does not perform the work supervised",
            ],
          },
          {
            header: "Functional Knowledge",
            content: [
              "Requires understanding and application of procedures and practices within own area and basic knowledge of other areas",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Understands how the team relates to other closely related teams to improve efficiency",
            ],
          },
          {
            header: "Leadership",
            content: [
              "Has formal supervisory responsibilities; sets priorities for and coaches associates to meet deadlines",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Uses judgment to identify and resolve day-to-day problems",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts the level of service and the team’s ability to meet quality, volume, and timeliness objectives within their area",
              "Guided by local business needs and established practices",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Uses tact and diplomacy to exchange information and address sensitive issues",
            ],
          },
        ],
      },
      {
        code: "L2",
        headers: [
          {
            header: "General Profile",
            content: [
              "Manages professional associates and/or supervisors",
              "Is accountable for the performance and results of a team(s)",
              "Adapts departmental plans and priorities to address resource and operational challenges",
              "Decisions are guided by policies, resources and business plan; receives moderate guidance from manager",
              "Provides subject matter guidance to associates, colleagues and/or customers",
            ],
          },
          {
            header: "Functional Knowledge",
            content: [
              "Requires in-depth conceptual and practical knowledge in own areas and knowledge of other areas",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Integrates industry/ functional best practices in own area to the achieve of objectives",
            ],
          },
          {
            header: "Leadership",
            content: [
              "Manages a generally homogeneous team; adapts plans and sets priorities to meet service and/or operational challenges",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Identifies and resolves moderately complex business problems",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts the level of service and the team’s ability to meet quality, volume and timeliness of objectives",
              "Guided by department policies, resource requirements, budgets and the business plan",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Guides, influences and persuades others internally and/or externally",
            ],
          },
        ],
      },
      {
        code: "L3",
        headers: [
          {
            header: "General Profile",
            content: [
              "Provides leadership to managers and/or professional associates",
              "Is accountable for the performance and results of multiple related teams",
              "Develops departmental plans, including business, production and/or organizational priorities",
              "Decision-making is guided by area objectives and resources",
              "Evaluates and allocates available resources within teams",
            ],
          },
          {
            header: "Functional Knowledge",
            content: [
              "Requires a comprehensive understanding of internal and industry standards and principles",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Applies in-depth understanding of the business dynamics of how own department integrates within an area or multiple areas",
            ],
          },
          {
            header: "Leadership",
            content: [
              "Manages multiple related teams, may supervise managers of managers, sets priorities and allocates resources; executes department business plans",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Identifies and resolves complex multi-dimensional business problems",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts the business results of a department/district by setting priorities, allocating resources,  and developing policies and plans",
              "Guided by department/district business plans",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Influences others internally and externally, including senior division executives, vendors or regulators",
            ],
          },
        ],
      },
      {
        code: "L4",
        headers: [
          {
            header: "General Profile",
            content: [
              "Provides leadership and direction through L3 managers",
              "Is accountable for the performance and results of an area or multiple related areas",
              "Executes sub-functional business plans and may contribute to the development of functional strategy",
              "Decisions are guided by sub-functional strategy, priorities",
              "Evaluates and allocates available resources within group",
            ],
          },
          {
            header: "Functional Knowledge",
            content: [
              "Requires comprehensive understanding of internal and industry standards, as well as of the changing business environment",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Applies industry expertise and awareness to drive financial and operational performance within an area or multiple related areas",
            ],
          },
          {
            header: "Leadership",
            content: [
              "Leads through subordinate managers of managers; executes sub-functional/sub-regional strategy",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Directs the resolution of highly complex or unusual business problems applying advance analytical thought and judgement",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts results of part of a division or a region by providing support to impact the company",
              "Guided by divisional strategy",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Negotiates with and influences others internally and externally; including senior executives within the company and vendors/or regulators",
            ],
          },
        ],
      },
      {
        code: "L5",
        headers: [
          {
            header: "General Profile",
            content: [
              "Typically provides leadership and direction through L4 managers or has significant impact on functional strategy",
              "Is accountable for the performance and results of a small sub-function, multiple areas or a large area within a function or business group",
              "Contributes to functional strategy and develops plans of execution for a small sub-function, multiple areas or a large area within a function or business group to achieve key business objectives",
              "Decisions are guided by functional strategy and objectives",
            ],
          },
          {
            header: "Functional Knowledge",
            content: [
              "Serves as a thought leader, integrates functional and business knowledge to anticipate changing business direction",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Applies in-depth business acumen and industry  expertise to differentiate the small sub-function, multiple areas or a large area within a function or business group from the competition",
            ],
          },
          {
            header: "Leadership",
            content: [
              "Leads a small sub-function, multiple areas or a large area within a function or business group, typically through multiple levels of managers of diverse activities; develops the strategy to achieve key business objectives",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Evaluates key business challenges; directs the development of new or innovative solutions",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts the results of a division",
              "Sets divisional strategy; guided by company strategy",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Negotiates with and influences others internally and externally, with great latitude on outcomes",
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Professional",
    slug: "professional",
    bgColor: theme.colors.primary.blue,
    textColor: theme.colors.grey.white,
    descriptions: [
      "Applies a theoretical knowledge-base to work to achieve goals through own work",
      "Characterized by specific job functional expertise typically gained through formal education​",
      "May provide guidance to others as a project manager using technical expertise",
    ],
    prePad: 3,
    postPad: 2,
    bands: [
      {
        code: "P1",
        headers: [
          {
            header: "General Profile",
            content: [
              "Performs routine assignments in the entry level",
              "Typically requires a college or university degree or the equivalent work experience that provides exposure to fundamental theories, principles and concepts",
              "Uses existing procedures to solve routine or standard problems",
              "Receives instruction, guidance and direction from others",
            ],
          },
          {
            header: "Functional Knowledge",
            content: [
              "Requires conceptual knowledge of practices and procedures within a particular area of expertise",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Applies general knowledge of business developed through education or past experience",
            ],
          },
          {
            header: "Leadership",
            content: [
              "No supervisory responsibilities; accountable for own contribution",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Solves routine problems using existing procedures and standard practices",
            ],
          },
          {
            header: "Impact",
            content: [
              "Has limited impact on own work team; works within standardized procedures and practices to achieve objectives and meet deadlines",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Exchanges basic information, asks questions and checks for understanding",
            ],
          },
        ],
      },
      {
        code: "P2",
        headers: [
          {
            header: "General Profile",
            content: [
              "Requires expanded knowledge and experience in own area; incumbents are still acquiring higher-level knowledge and skills",
              "Builds knowledge of Alliant, its processes and customers",
              "Solves a range of straightforward problems",
              "Analyzes possible solutions using standard procedures",
              "Receives a moderate level of guidance and direction",
            ],
          },
          {
            header: "Functional Knowledge",
            content: [
              "Requires expanded conceptual knowledge in own area of expertise while developing additional skill sets",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Understands key business drivers; uses this understanding to accomplish own work",
            ],
          },
          {
            header: "Leadership",
            content: [
              "No supervisory responsibilities but may provide guidance to new associates",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Solves standard problems by analyzing possible solutions using experience, judgment and precedents",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts quality of own work and the work of others on the team; works within guidelines and policies",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Explains information to others in straightforward situations",
            ],
          },
        ],
      },
      {
        code: "P3",
        headers: [
          {
            header: "General Profile",
            content: [
              "Requires in-depth knowledge and experience",
              "Uses best practices and knowledge of internal or external business issues to improve products or services",
              "Solves complex problems; takes a new perspective using existing solutions",
              "Works independently, receives minimalguidance",
              "Acts as a resource for colleagues with less experience",
            ],
          },
          {
            header: "Functional Knowledge",
            content: [
              "Requires conceptual and practical expertise in own area and general knowledge of related areas",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Has knowledge of best practices and how own area integrates with others; is aware of the competition and the factors that differentiate Alliant in the industry",
            ],
          },
          {
            header: "Leadership",
            content: [
              "Acts as a resource for other team members; may lead projects with limited risks and resource requirements",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Solves complex problems by taking a new perspective on existing solutions; exercises judgment based on the analysis of multiple sources of information",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts a range of customer, operational, project or service activities within own team and other related teams; works within broad guidelines and policies",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Explains complex, difficult and/or sensitive information; works to build consensus",
            ],
          },
        ],
      },
      {
        code: "P4",
        headers: [
          {
            header: "General Profile",
            content: [
              "Requires specialized depth and/or breadth of expertise",
              "Interprets internal or external business issues and recommends best practices",
              "Solves complex problems; takes a broad perspective to identify innovative solutions",
              "Works independently, with guidance in only the most complex situations",
              "May lead functional teams or projects",
            ],
          },
          {
            header: "Functional Knowledge",
            content: [
              "Requires depth and/or breadth of expertise in own specialized area and general knowledge of related areas",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Interprets internal/external business challenges and recommends best practices to improve products, processes or services",
            ],
          },
          {
            header: "Leadership",
            content: [
              "May lead teams or projects with moderate resource requirements, risk, and/or complexity",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Leads others to solve complex problems; uses analytical thinking, tools and judgement to identify innovative solutions",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts the achievement of customer, operational, project or service objectives; work is guided by department objectives",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Communicates complex concepts; anticipates potential objections and influences others to adopt a different point of view",
            ],
          },
        ],
      },
      {
        code: "P5",
        headers: [
          {
            header: "General Profile",
            content: [
              "Requires recognized expertise within Alliant (known expert within Alliant)",
              "Anticipates internal and or external business challenges, vendor and/or regulatory issues; recommends process, product or service improvements",
              "Solves unique and complex problems that have a broad impact on the business",
              "Progression to this level is restricted on the basis of business requirements",
            ],
          },
          {
            header: "Functional Knowledge",
            content: [
              "Requires a company subject matter expert in their particular discipline",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Anticipates business, vendor and/or regulatory changes; recommends product, process or service improvements",
            ],
          },
          {
            header: "Leadership",
            content: [
              "Leads projects with notable risk and complexity; develops the strategy for project execution",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Solves unique and complex problems with broad impact on the business; requires conceptual and innovative thinking to develop solutions",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts the achievement of customer, operational, project or service objectives by setting the direction and allocating resources; works within division objectives",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Communicates complex concepts, anticipates potential objections and persuades senior executives, to adopt a different point of view",
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Technical Support",
    slug: "technical-support",
    bgColor: theme.colors.secondary.orange,
    textColor: theme.colors.grey.black,
    descriptions: [
      "Performs technical work, often in conjunction with professional roles",
      "Requires vocational training or the equivalent experience",
      "Performs duties according to established procedures",
    ],
    prePad: 1,
    postPad: 5,
    bands: [
      {
        code: "T1",
        headers: [
          {
            header: "General Profile",
            content: [
              "Entry-level position with appropriate licenses/training certifications and/or equivalent experience",
              "Has basic skills in an analytical or technical process",
              "Works within clearly defined standard operating procedures and adheres to quality guidelines",
              "Work is completed with close supervision",
            ],
          },
          {
            header: "Functional Knowledge",
            content: [
              "Applies basic analytical and technical skills to perform routine activities",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Understands how the assigned tasks relate to others in the team and how the team integrates with others",
            ],
          },
          {
            header: "Leadership",
            content: ["Has no supervisory responsibilities"],
          },
          {
            header: "Problem Solving",
            content: [
              "Uses standard operating procedures to guide own work and solve routine problems",
            ],
          },
          {
            header: "Impact",
            content: ["Impacts the quality of own work"],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Uses communication skills and common courtesy to exchange information",
            ],
          },
        ],
      },
      {
        code: "T2",
        headers: [
          {
            header: "General Profile",
            content: [
              "Expands analytical or technical skills in own work area",
              "Maintains appropriate licenses, training and/or certifications, as needed",
              "Applies experience and skills to complete assigned work within our area of expertise",
              "Works within standard operating procedures and/or analytical methods",
              "Works with a moderate degree of supervision",
            ],
          },
          {
            header: "Functional Knowledge",
            content: [
              "Applies analytical and technical skills to perform a variety of activities",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Has good understanding of how the team integrates with others",
            ],
          },
          {
            header: "Leadership",
            content: ["Has no supervisory responsibilities"],
          },
          {
            header: "Problem Solving",
            content: [
              "Recognizes and solves routine problems that can occur in own work area with or without supervisory approval; evaluates and selects solutions from established operating procedures",
            ],
          },
          {
            header: "Impact",
            content: ["Impacts the quality and quantity of own work"],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Uses communication skills and common courtesy to exchange information",
            ],
          },
        ],
      },
      {
        code: "T3",
        headers: [
          {
            header: "General Profile",
            content: [
              "Has developed proficiency in a range of processes or procedures through job-related training and considerableon-the-job experience",
              "Completes a variety of atypical assignments, as needed",
              "Works within defined processes and procedures or methodologies and may help determine the appropriate approach for new assignments",
              "Likely to act as an informal resource for associates with less experience",
            ],
          },
          {
            header: "Functional Knowledge",
            content: [
              "Requires proficiency in a range of analytical and technical processes or procedures to carry out assigned tasks",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Has a good understanding of how the team integrates with others to achieve department objectives",
            ],
          },
          {
            header: "Leadership",
            content: [
              "May provide informal guidance and support to team members",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Provides solutions to problems in situations that are atypical based on existing precedents or procedures",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts the quality and quantity of own work and its contribution to the team",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Uses communication skills to effectively exchange information; uses tact and diplomacy when dealing with others",
            ],
          },
        ],
      },
      {
        code: "T4",
        headers: [
          {
            header: "General Profile",
            content: [
              "Has advanced and specialized expertise in an analytical/technical processes, typically developed through a combination of job-related training and considerable on-the-job experience",
              "Completes assignments and facilitates the work activities of others; may coordinate work beyond own area",
              "Proposes improvements to processes and methods",
              "Acts as a lead, coordinating the work of others  but is not a supervisor",
            ],
          },
          {
            header: "Functional Knowledge",
            content: [
              "Requires developed advanced specialized expertise within an analytical/technical area to perform the most complex work",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Has a good understanding of how related teams coordinate their efforts and resources to achieve department objectives",
            ],
          },
          {
            header: "Leadership",
            content: [
              "Serves as team lead, may allocate work and provide guidance and training to team members",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Exercises judgment to identify and solve problems that arise with little or no precedent",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts the effectiveness of own team and closely related teams",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Exchanges ideas and information effectively; uses tact and diplomacy when dealing with others",
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Business Support",
    slug: "business-support",
    bgColor: theme.colors.secondary.yellowLight,
    textColor: theme.colors.grey.textDark,
    descriptions: [
      "Performs clerical or administrative, work",
      "Uses administrative, data organizing and coordination skills to complete work",
      "Performs duties according to established procedures",
    ],
    prePad: 0,
    postPad: 5,
    bands: [
      {
        code: "B1",
        headers: [
          {
            header: "General Profile",
            content: [
              "Entry-level position with limited prior training or relevant work experience",
              "Acquires basic skills to perform routine tasks",
              "Work is prescribed and completed with little autonomy",
              "Works with either close supervision or under clearly defined procedures",
            ],
          },
          {
            header: "Functional Knowledge",
            content: ["Develops skills to perform basic activities in own job"],
          },
          {
            header: "Business Expertise",
            content: [
              "Understands how the assigned duties integrate with others in the team",
            ],
          },
          {
            header: "Leadership",
            content: [
              "Uses existing procedures to solve routine problems; has limited discretion",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Uses existing procedures to solve routine problems; has limited discretion",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts the accuracy and quality of own work; receives close supervision; duties are clearly defined",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: ["Uses communication skills and common courtesy"],
          },
        ],
      },
      {
        code: "B2",
        headers: [
          {
            header: "General Profile",
            content: [
              "Requires knowledge and skills gained through formal training or considerable work experience",
              "Works within established procedures with a moderate degree of supervision",
            ],
          },
          {
            header: "Functional Knowledge",
            content: [
              "Requires established skills to perform a range of day-to-day activities",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Understands how the assigned duties relate to others in the team and how the team integrates with other teams",
            ],
          },
          {
            header: "Leadership",
            content: [
              "Solves routine problems without supervisory approval; evaluates and selects solutions from established options",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Solves routine problems without supervisory approval; evaluates and selects solutions from established options",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts own team through the accuracy and quality of work; follows procedures and receives regular supervision",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: ["Uses communication skills to exchange information"],
          },
        ],
      },
      {
        code: "B3",
        headers: [
          {
            header: "General Profile",
            content: [
              "Requires specialized skills or is multi-skilled developed throughjob-related training and considerable on-the-job experience",
              "Completes work with a limited degree of supervision",
              "Likely to act as an informal resource for associates with less experience",
            ],
          },
          {
            header: "Functional Knowledge",
            content: [
              "Requires skills in a range of processes, procedures and systems to carry out assigned tasks or has developed deep skills in a single area",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Applies knowledge of how the team integrates with other teams to achieve objectives",
            ],
          },
          {
            header: "Leadership",
            content: [
              "Provides solutions to atypical problems based on proven practices or procedures",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Provides solutions to atypical problems based on proven practices or procedures",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts the quality, timeliness and effectiveness of the team; recommends changes to improve efficiency",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: ["Explains job specific information"],
          },
        ],
      },
      {
        code: "B4",
        headers: [
          {
            header: "General Profile",
            content: [
              "Requires expertise in a variety of work processes through a combination of job-related training and considerable on-the-job experience",
              "Typically acts as a lead, coordinating the work of others  but not a supervisor",
              "Works autonomously within established procedures and practices",
            ],
          },
          {
            header: "Functional Knowledge",
            content: [
              "Requires breadth and/or depth of skills in a range of processes, procedures and systems",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Requires in-depth knowledge of department processes and procedures",
            ],
          },
          {
            header: "Leadership",
            content: [
              "Gathers information to solve problems that are escalated from team members",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Gathers information to solve problems that are escalated from team members",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts own team and closely related teams; defines and recommends process improvements",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: ["Evaluates and communicates job-specific information"],
          },
        ],
      },
      {
        code: "B5",
        headers: [
          {
            header: "General Profile",
            content: [
              "Functions as a working supervisor of a business support team; works autonomously and may spend a portion of the time performing as an informal supervisor",
              "Responsible for results of the team",
              "Works within established precedents ad practices",
              "Analyzes information to solve on-going problems",
            ],
          },
          {
            header: "Functional Knowledge",
            content: [
              "Requires comprehensive understanding of processes, procedures and systems within own and related areas",
            ],
          },
          {
            header: "Business Expertise",
            content: [
              "Uses knowledge of how the team integrates with other teams to coordinate efforts and resources to achieve shared and individual objectives",
            ],
          },
          {
            header: "Leadership",
            content: [
              "Uses experience to identify and solve day-to-day problems",
            ],
          },
          {
            header: "Problem Solving",
            content: [
              "Uses experience to identify and solve day-to-day problems",
            ],
          },
          {
            header: "Impact",
            content: [
              "Impacts the overall quality and quantity of the tasks performed by the team",
            ],
          },
          {
            header: "Interpersonal Skills",
            content: [
              "Requires tact and diplomacy to communicate ideas in a concise and logical way",
            ],
          },
        ],
      },
    ],
  },
]

export default careerLevels
